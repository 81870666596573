<template>
  <div>
    <div>
      <breadcrumb :breadcrumb_link="marketingmain_breadcrumb" />
    </div>

    <div>
      <Marketingaffiliate v-show="stage === 0" @nextPage="nextPage" />
    </div>
    <div>
      <Marketingcontact v-show="stage === 1" />
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb';
import Marketingaffiliate from '@/pages/Footerlink/Marketingaffiliate';
import Marketingcontact from '@/pages/Footerlink/Marketingcontact';

export default {
  components: {Breadcrumb, Marketingaffiliate, Marketingcontact},
  name: 'MarketingMain',
  data() {
    return {
      marketingmain_breadcrumb: {
        toplink: 'HOME',
        prevlink: '마케팅 제휴 문의',
        present: '마케팅 제휴 문의',
      },
      stage: 0,
    };
  },
  methods: {
    nextPage() {
      this.stage = 1;
      window.scrollTo(0, 0);
    },
  },
  mounted() {
    this.$store.dispatch('Display/changeName', {name: '마케팅 제휴'});
  },
};
</script>

<style scoped></style>
